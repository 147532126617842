import { api } from "../core/api";

export default {
    getUsers(params) {
        return api.get("/ApplicationUser/GetAll", { params: params });
    },
    getUser(param) {
        return api.get("/ApplicationUser/Get", { params: param });
    },
    addUser(data) {
        return api.post("/ApplicationUser/Add", data);
    },
    updateUser(data) {
        return api.patch("/ApplicationUser/Update", data);
    },
    toogleActivate(id) {
        return api.put(`/ApplicationUser/ToogleActivate?id=${id}`);
    },
    deleteUser(id) {
        return api.delete(`/ApplicationUser/Delete?id=${id}`);
    },
    getResumenUserByRole() {
        return api.get("/ApplicationUser/GetResumenUsersResumenByRole");
    },
};
