<template>
    <div class="d-flex flex-row flex-wrap">
        <div class="w-100">
            <Header title="" />
        </div>
        <left-side-bar />
        <div class="d-flex flex-row flex-wrap align-items-center w-75 pl-3">
            <div
                class="
                    d-flex
                    flex-row flex-wrap
                    justify-between
                    align-items-center
                    w-100
                "
            >
                <h1 class="font-xx-large font-weight-bold">Transporte</h1>
            </div>
            <el-tabs
                @tab-click="onTabChanged"
                v-model="activeName"
                class="w-100"
            >
                <el-tab-pane label="Rutas" name="first"></el-tab-pane>
            </el-tabs>
            <h1 class="font-x-large font-weight-bold">Rutas</h1>
            <nav class="d-flex flex-row align-items-center text-gray-dark ml-3">
                <router-link
                    to="routes"
                    class="d-flex flex-row align-items-center text-gray-dark"
                >
                    <i
                        class="material-icons text-gray-dark pr-2"
                        style="font-size: 18px"
                        >arrow_back</i
                    >
                    Rutas
                </router-link>
                <i class="p-5">/</i>
                <router-link to="#" class="text-gray-dark white-space-nowrap">
                    Creaci&oacute;n de ruta
                </router-link>
            </nav>
            <div class="d-flex flex-row flex-wrap w-100 pt-3 pl-1">
                <div class="d-flex flex-column pr-3">
                    <h3
                        class="
                            text-secondary
                            m-0
                            pl-3
                            white-space-nowrap
                            font-large
                        "
                    >
                        Informaci&oacute;n de la ruta
                    </h3>
                    <div class="d-flex flex-column pl-3 pt-10 font-medium">
                        <div class="d-flex flex-column">
                            <span>Fecha</span>
                            <span
                                class="
                                    text-black
                                    font-medium
                                    mt-2
                                    font-weight-bold
                                "
                                >{{
                                    moment(route.date).format("DD MMM YYYY")
                                }}</span
                            >
                        </div>
                        <div class="d-flex flex-column mt-10">
                            <span>Turno</span>
                            <i class="material-icons text-primary mt-2">{{
                                parseInt(route.shift_day)
                                    ? "light_mode"
                                    : "nightlight"
                            }}</i>
                        </div>
                    </div>
                </div>
                <div
                    class="d-flex flex-column pl-3 w-75"
                    style="border-left: 1px solid #c0c4cc"
                >
                    <h3
                        class="
                            text-secondary
                            m-0
                            white-space-nowrap
                            font-large
                            w-100
                        "
                    >
                        Ruta
                    </h3>
                    <div class="d-flex flex-column pt-2 font-medium w-100">
                        <div
                            class="
                                bg-primary
                                p-1
                                w-100
                                d-flex
                                flex-row
                                align-items-center
                            "
                            style="border-radius: 4px"
                        >
                            <div
                                class="
                                    d-flex
                                    flex-row
                                    align-items-center
                                    text-white
                                    font-small
                                "
                            >
                                <i
                                    class="material-icons text-white pr-3"
                                    style="font-size: 17px"
                                    >person</i
                                >
                                Delivery
                            </div>
                            <div
                                class="ml-2 text-white material-icons"
                                style="font-size: 17px"
                            >
                                navigate_next
                            </div>
                            <div
                                class="
                                    d-flex
                                    flex-row
                                    align-items-center
                                    text-white
                                    font-small
                                    pl-1
                                "
                            >
                                <i
                                    class="material-icons text-white pr-3"
                                    style="font-size: 17px"
                                    >location_on</i
                                >
                                Zona
                            </div>
                            <div
                                class="ml-2 text-white material-icons"
                                style="font-size: 17px"
                            >
                                navigate_next
                            </div>
                            <div
                                class="
                                    d-flex
                                    flex-row
                                    align-items-center
                                    text-white
                                    font-small
                                    pl-1
                                "
                            >
                                <i
                                    class="material-icons text-white pr-3"
                                    style="font-size: 17px"
                                    >receipt_long</i
                                >
                                &Oacute;rdenes
                            </div>
                        </div>
                        <div
                            class="
                                mt-3
                                d-flex
                                flex-row flex-wrap
                                align-items-center
                            "
                        >
                            <span
                                class="
                                    font-medium
                                    text-dark
                                    font-weight-bold
                                    white-space-nowrap
                                "
                                >Selecciona un {{ tabRole }}</span
                            >

                            <el-row
                                :gutter="20"
                                class="m-0 w-75"
                                style="margin-left: 0; margin-right: 0"
                            >
                                <el-col :span="8">
                                    <div class="w-100">
                                        <el-input
                                            v-model="searchByquery"
                                            placeholder="Selecciona un delivery"
                                            style="width: 250px"
                                        >
                                            <template
                                                style="color: white"
                                                #append
                                            >
                                                <el-button
                                                    style="
                                                        background-color: #3f69a6;
                                                    "
                                                    @click="searchByQuery()"
                                                    type="primary"
                                                >
                                                    <i
                                                        style="color: white"
                                                        class="
                                                            el-icon-search
                                                            el-icon-center
                                                        "
                                                    ></i>
                                                </el-button>
                                            </template>
                                        </el-input>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="w-100 mt-3 border">
                            <el-table
                                ref="multipleTable"
                                :data="tableData"
                                style="width: 100%; max-width: 90vw"
                                @selection-change="handleSelectionChange"
                                @row-click="rowClicked"
                            >
                                <el-table-column type="selection" width="55" />
                                <el-table-column
                                    label="Código"
                                    property="userNumber"
                                    width="220"
                                />
                                <el-table-column
                                    property="firstName"
                                    label="Nombre"
                                    width="200"
                                />
                                <el-table-column
                                    property="lastName"
                                    label="Apellido"
                                    width="200"
                                />
                            </el-table>
                            <el-row :gutter="20">
                                <el-col :span="12" :offset="6">
                                    <div class="block">
                                        <el-pagination
                                            layout="prev, pager, next"
                                            :page-size="page_size"
                                            :page-count="total_pages"
                                            :total="total_count"
                                            @size-change="handleSizeChange"
                                            @current-change="
                                                handleCurrentChange
                                            "
                                        ></el-pagination>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                    <div
                        class="
                            d-flex
                            flex-row flex-wrap
                            justify-between
                            text-align-center
                            mt-5
                        "
                    >
                        <router-link to="routes" class="">
                            <button
                                class="btn bg-white pl-3 pr-3 text-center"
                                style="width: 100px"
                            >
                                Cancelar
                            </button>
                        </router-link>

                        <el-button
                            @click="gotoStep2()"
                            :disabled="this.multipleSelection.length == 0"
                            class="pull-end bg-yellow"
                            round
                        >
                            Siguiente
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import Header from "@/components/dashboard/include/Header";
import _service from "@/services/userService";
import moment from "moment";
import leftSideBar from "@/components/dashboard/menu/leftSideBar";

export default defineComponent({
    name: "Step1",
    components: { Header, leftSideBar },
    data() {
        return {
            searchByquery: "",
            drawer: ref(false),
            activeName: "first",
            page_size: 10,
            page_index: 1,
            total_count: 23,
            total_pages: 10,
            tableData: [],
            multipleSelection: [],
            route: {
                date: null,
                shift_day: null,
                shift_night: null,
            },
        };
    },
    created() {
        this.getAllUsers(10, 1, "Delivery", this.searchByquery);
        this.moment = moment;
        this.route.date = localStorage.getItem("route_date");
        this.route.shift_day = localStorage.getItem("route_shift_day");
        this.route.shift_night = localStorage.getItem("route_shift_night");

        //localStorage.removeItem('route_deliveries');
    },
    mounted() {
        this.tabRole = "Delivery";
    },
    methods: {
        handleSizeChange(val) {
            console.log(`${val} items per page`);
        },
        onTabChanged(name) {
            this.tabRole = name.props.label;
        },
        handleCurrentChange(val) {
            const data = {
                PageSize: this.page_size,
                PageIndex: val,
                Role: this.role,
            };
            _service.getUsers(data).then((user) => {
                this.tableData = user.data.items;
            });
        },
        rowClicked(row) {
            this.$emit("editUser", row);
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            localStorage.setItem("route_deliveries", []);
            localStorage.setItem(
                "route_deliveries",
                JSON.stringify(this.multipleSelection)
            );
        },
        gotoStep2() {
            this.$router.push("RouteCreationStep2");
        },
        searchByQuery() {
            const data = {
                Query: this.searchByquery,
                PageSize: this.page_size,
                PageIndex: this.page_index,
                Role: this.role,
            };

            _service.getUsers(data).then((user) => {
                this.tableData = user.data.items;
            });
        },
        getAllUsers(page_size, page_index, role) {
            const data = {
                PageSize: page_size,
                PageIndex: page_index,
                Role: role,
            };
            _service.getUsers(data).then((data) => {
                this.total_pages = data.data.totalPages;
                this.total_count = data.data.pageSize;
                this.tableData = data.data.items;
            });
        },
    },
});
</script>
<style scoped>
.color-icon {
    color: white;
}
.ctpc {
    padding: 0%;
}
.texto1 {
    margin-left: 20%;
    font: normal normal 300 18px/18px Roboto;
    letter-spacing: 0px;
    color: #3f69a6;
    opacity: 1;
    margin-top: 25%;
}
.texto2 {
    margin-left: 10%;
    font: normal normal 300 18px/18px Roboto;
    letter-spacing: 0px;
    color: #3f69a6;
    opacity: 1;
    margin-top: 6.4%;
}

.containerbody {
    background: #fbfbff 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
}

.borde {
    border-left: 1px solid #c0c4cc;
}
</style>
